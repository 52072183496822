import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Typography,
  Box,
  Modal,
  CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { BiCommentDetail } from 'react-icons/bi';
import Comments from './Comments';

const reportabusivestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  height: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const styleComment = {
  position: 'absolute' as 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 54,
  p: 4
};

const Posts = ({ data, getAllPosts }) => {
  const [postId, setPostId] = useState('');
  const [reportModalopen, setReportModalOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [reportMessage, setReportMessage] = useState('');
  const [reportError, setReportError] = useState(false);
  const userrID = localStorage.getItem('USER_ID');
  const [comment, setComment] = useState('');
  const [postCommentid, setPostCommentId] = useState('');
  const [emptyComment, setEmptyComment] = useState(false);
  const [showChats, setShowChats] = useState(true);
  const [postCommentModal, setPostCommentOpen] = useState(false);
  const [isPostLoading, setisPostLoading] = useState(false);
  const [isReportPostLoading, setisReportPostLoading] = useState(false);

  const fullDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  //Modal opens
  const handleReportOpen = (post) => {
    setPostId(post);
    setReportModalOpen(true);
  };
  const handleReportClose = () => {
    setisReportPostLoading(false);
    setReportError(false);
    setReportMessage('');
    setReportModalOpen(false);
  };

  useEffect(() => {
    if (reportMessage) {
      setReportError(false);
    }
  }, [reportMessage]);

  const handleReportAbusivePost = (e) => {
    e.preventDefault();
    if (reportMessage !== '') {
      setisReportPostLoading(true);

      let data = {
        reportedBy: userrID,
        reportMessage: reportMessage
      };
      axios
        .patch(
          `${process.env.REACT_APP_HOME_URL}/post/reportabuse/${postId}`,
          data
        )
        .then((res: any) => {
          setReportMessage('');
          setisReportPostLoading(false);
          setReportModalOpen(false);
          toast.success(
            'Request to report this post has been successfully submitted.',
            {
              position: 'bottom-right',
              theme: 'dark'
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setReportError(true);
    }
  };

  const handleCommentOpen = (postid) => {
    setPostCommentId(postid);
    setPostCommentOpen(true);
  };
  const handleCommentClose = () => {
    setComment('');
    setEmptyComment(false);
    setPostCommentOpen(false);
  };

  const handleComment = async (e) => {
    try {
      e.preventDefault();
      if (comment === '') {
        setEmptyComment(true);
        return;
      }
      setisPostLoading(true);
      let Tdata = {
        userId: userrID,
        postId: postCommentid,
        comment
      };

      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}/comment/add`,
        Tdata
      );
      setComment('');
      setisPostLoading(false);
      setPostCommentOpen(false);
      getAllPosts();
      setEmptyComment(false);
      toast.success('Successfully commented on this post.', {
        position: 'bottom-right',
        theme: 'dark'
      });
    } catch (err) {
      console.log(err);
    }
  };

  const toggleReplies = () => {
    setShowChats(!showChats);
  };

  return (
    <>
      <Card
        key={data?._id}
        sx={{
          mb: 1,
          backgroundColor: '#EDFFE5',
          border: '1px solid rgb(147, 251, 66)',
          borderRadius: '14px'
        }}
        className={'timelinepost'}
      >
        <CardHeader
          avatar={
            <Typography
              sx={{
                width: '35px',
                height: '35px',
                mr: '-5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid gray',
                borderRadius: '5px !important',
                fontSize: '1.2rem',
                background: '#0d5400',
                color: '#fff'
              }}
            >
              {data?.user[0]?.username?.[0]}
            </Typography>
          }
          titleTypographyProps={{ variant: 'h3' }}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
          subheader={
            <>
              <p className="timelineCardUser">
                <span>{data?.user[0]?.username}</span>
                <span className="seperator">|</span>
                <span>{data?.accessibility}</span>
              </p>
              <span className="timelineCardDate">
                {moment(data?.created_at).format('D MMM YYYY, hh:mm A')}
              </span>
            </>
          }
        />
        <div className="postArea" style={{ padding: '0 16px' }}>
          {(data.mediaType === 'image/jpeg' ||
            data.mediaType === 'image/jpg' ||
            data.mediaType === 'image/png') && (
            <Box
              sx={{ minHeight: 300, maxHeight: 300 }}
              //image={data?.media}
              //component={'img'}
              style={{ 
                backgroundImage: `url(${data?.media})`,
                backgroundPosition: 'top center',
                backgroundSize: 'cover'
              }}
            />
          )}

          {(data?.mediaType === 'video/mp4' ||
            data?.mediaType === 'video/webm') && (
            <video width="100%" height="240" controls muted>
              <source src={data?.media} type={data?.mediaType}></source>
            </video>
          )}
          {(data?.mediaType === 'audio/mp3' ||
            data?.mediaType === 'audio/mpeg') && (
            <audio style={{ width: '670', height: '240' }} controls>
              <source src={data?.media} type={data?.mediaType}></source>
            </audio>
          )}
        </div>

        <Box
          sx={{
            my: 1,
            pl: 2,
            pr: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <label
              htmlFor=""
              style={{ cursor: 'pointer', padding: 0, margin: 0 }}
            >
              <BiCommentDetail
                onClick={() => handleCommentOpen(data?._id)}
                size={'1.5em'}
                style={{ marginRight: 5 }}
              />
              {data?.comments?.length}
            </label>
          </Box>
          <Box>
            {data?.user[0]?._id === userrID ? (
              ' '
            ) : (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Report Abusive"
                  onClick={() => handleReportOpen(data?._id)}
                  sx={{ fontSize: '12px' }}
                />
              </FormGroup>
            )}
          </Box>
        </Box>

        
        <Box
          p={2}
        >
           <Box
            p={0}
            style={{
              position: 'relative'
            }}
        >
          <Typography
            variant="h4"
            fontWeight="normal"
            style={{
              color: '#434343',
              textTransform: 'capitalize',
              // fontSize: '12px',
              paddingRight: '100px',
              wordBreak: 'break-word'
            }}
          >
            {showFullDescription
              ? data?.description
              : data?.description.substr(0, 100)}
          </Typography>
          {data?.description?.length > 100 && (
            <button
              onClick={fullDescription}
              style={{
                position: 'absolute',
                right: '20px',
                bottom: '0px',
                background: 'transparent',
                border: 'none',
                color: '#4A47F3'
              }}
              className="popinFont"
            >
              {showFullDescription ? 'See Less' : '...See More'}
            </button>
          )}
        </Box>
          {/* comments */}
          <Box
            sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-end' }}
          >
            {data?.comments?.length > 1 && (
              <Button
                onClick={toggleReplies}
                color="primary"
                size="small"
                variant="outlined"
              >
                {showChats ? 'Hide Chats' : 'View All Chats'}
              </Button>
            )}
          </Box>
          {/* {data?.comments?.length > 0 && (
            <Comments comment={data?.comments[0]} getAllPosts={getAllPosts} />
          )} */}

          {showChats && (
            <>
              {data?.comments?.length > 0 &&
                data?.comments?.map((comment) => (
                    <Comments
                      getAllPosts={getAllPosts}
                      comment={comment}
                      key={comment?._id}
                    />
                  ))}
            </>
          )}
        </Box>
      </Card>

      {/* Post a comment modal */}
      <Modal
        open={postCommentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCommentClose}
      >
        <Box sx={styleComment}>
          <textarea
            className="form-control"
            id="comment"
            placeholder="What are your thoughts?"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {emptyComment && (
            <div className="invalid-data">This field is required.</div>
          )}

          <div style={{ marginLeft: '75%' }}>
            <Button
              sx={{ mt: 3, px: 4, py: 1.5 }}
              variant="contained"
              onClick={handleComment}
              disabled={isPostLoading}
            >
              {isPostLoading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size="1.5rem" color="inherit" />
                </Box>
              ) : (
                'POST'
              )}
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Report Abusive Modal */}
      <Modal
        open={reportModalopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <Box sx={reportabusivestyle}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Kindly specify your reasons.
          </Typography>
          <hr />
          <form onSubmit={handleReportAbusivePost}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Give reason here."
              style={{ width: 380 }}
              value={reportMessage}
              onChange={(e) => setReportMessage(e.target.value)}
            />
            {reportError && (
              <div className="invalid-data">Please give valid reason.</div>
            )}

            <Button type="submit" sx={{ mt: 2 }} variant="contained">
              {isReportPostLoading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size="1.5rem" color="inherit" />
                </Box>
              ) : (
                'Yes'
              )}
            </Button>
            <Button
              sx={{ mt: 2, ml: 2 }}
              onClick={handleReportClose}
              variant="contained"
            >
              No
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Posts;
