import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import './nfts.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAddress } from '@thirdweb-dev/react';
import LoaderAnimation from 'src/components/LoaderAnimation/LoaderAnimation';

const MYNFTS = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem('USER_ID');
  const address = useAddress();
  const [NFTS, setNFTS] = useState([]);
  const [LoadingNfts, setLoadingNfts] = useState(true);
  const [ErrorNfts, setErrorNfts] = useState(false);
  const [switchNetwork, setswitchNetwork] = useState(true);
  const [switchNetworkErr, setswitchNetworkErr] = useState(false);

  const redirect = (identifier, contract) => {
    navigate(`/management/nfts-details/${contract}/${identifier}`);
  };

  const gotoMarketplace = () => {
    navigate('/components/marketplace');
  };

  const nftListing = async () => {
    try {
      setLoadingNfts(true);
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/nft/${userId}`
      );
      const data = response?.data?.data;
      setLoadingNfts(false);
      setNFTS(data);
    } catch (err) {
      console.error('Error fetching data:', err);
      setLoadingNfts(false);
    }
  };

  useEffect(() => {
    nftListing();
    switchN();
  }, []);

  const switchN = async () => {
    setswitchNetwork(true);
    const provider = window.ethereum;
    const polygonChainId = '0x89';

    if (!provider) {
     return;
    } else {
      const chainId = await provider.request({ method: 'eth_chainId' });
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: polygonChainId }]
        });
        setswitchNetwork(false);
      } catch (switchError) {
        setswitchNetworkErr(true);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await provider
              .request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x89',
                    chainName: 'Polygon Mainnet',
                    rpcUrls: ['https://polygon-rpc.com'],
                    blockExplorerUrls: ['https://www.polygonscan.com'],
                    nativeCurrency: {
                      name: 'MATIC',
                      symbol: 'MATIC',
                      decimals: 18
                    }
                  }
                ]
              })
              .then(() => console.log('Polygon Matic added successfully'))
              .catch((error) => console.log('err:', error.message));
          } catch (addError) {
            // handle "add" error
            console.log("addError",addError);
          }
        }
      }
    }
  };

  return (
    <>
      <div className="page-title">
        <p
          className="russoFont afterBorder"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            fontSize: '26px',
            textTransform: 'capitalize',
            color: '#27AA58',
            margin: '0'
          }}
        >
          MY NFT(S)
        </p>
      </div>

      <div
        style={{
          backgroundImage:
            'url(../static/images/tree-bg.png)',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh'
        }}
      >
        <Box
          style={{
            backgroundColor: '#EDFFE5',
            border: '1px solid #93FB42',
            borderRadius: 18,
            padding: 30,
            maxWidth: '90%',
            margin: '20px auto'
          }}
        >

            {LoadingNfts && (
              <Box sx={{ display: 'flex', mb:4 }}>
                <LoaderAnimation />
              </Box>
            )}

            <Grid
              container
              spacing={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
            {address && (!switchNetwork) ? (
              <>
                {NFTS.length > 0 ? (
                  NFTS.map(
                    (nft: any) =>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={nft?.identifier}
                          onClick={() =>
                            redirect(nft?.identifier, nft?.contract)
                          }
                        >
                          <Card
                            className="carddata"
                            sx={{
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <CardMedia
                              sx={{
                                height: 300,
                                borderRadius: 2,
                                padding: 0.2
                              }}
                              src={nft?.image_url}
                              // src="../static/images/nft-land-img.png"
                              component="img"
                            />
                            <CardContent>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Box className="textBox">
                                  <Typography
                                    gutterBottom
                                    component="div"
                                    className="Names"
                                    sx={{ fontSize: 24 }}
                                  >
                                    {nft?.name}
                                  </Typography>

                                  {/* <Typography
                              className="Tokenname"
                              sx={{ fontSize: 16 }}
                            >
                              <span>#{nft?.id}</span>
                            </Typography> */}
                                </Box>
                                <Box className="Imagetext">
                                  <img
                                    src="../static/images/Appleimage.png"
                                    className="Images"
                                  />
                                  <span className="Apples">{nft?.height}</span>
                                </Box>
                              </Box>
                              <Box className="bg">
                                <Typography
                                  className="scoretext"
                                  sx={{ fontSize: 18 }}
                                >
                                  Score <span className="span-text">{nft?.land}</span>
                                </Typography>
                                <Typography
                                  className="scoretext"
                                  sx={{ fontSize: 18 }}
                                >
                                  Rank <span className="span-text">{nft?.rank}</span>
                                </Typography>
                                <Typography
                                  className="scoretext"
                                  sx={{ fontSize: 18 }}
                                >
                                  Level{' '}
                                  <span className="span-text1">
                                    {nft?.currentLevel}
                                  </span>
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                ) : (
                  <Card
                    className="noNFTs"
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom variant="h3" component="div">
                        No NFTs found!
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Please purchase CLW Land NFTs from the Marketplace.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        onClick={gotoMarketplace}
                      >
                        Go to Marketplace
                      </Button>
                    </CardActions>
                  </Card>
                )}
              </>
            ) : (
             <span style={{fontSize:'15px', padding:'50px'}}>Please connect wallet first to see your NFTs.</span>
            )}
           
          </Grid> 
        </Box>
      </div>
    </>
  );
};

export default MYNFTS;
