import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import bmTranslation from './Languages/bm.json';
import enTranslation from './Languages/en.json';

// import { mainnet, configureChains, createClient, WagmiConfig } from 'wagmi';
// import { publicProvider } from "wagmi/providers/public";
// const { provider, webSocketProvider } = configureChains(
//   [mainnet],
//   [publicProvider()]
// );
// const client = createClient({
//   autoConnect: true,
//   provider,
//   webSocketProvider,
// });

// const domNode = document.getElementById('root');
// const root = createRoot(domNode);
// root.render(
//   <HelmetProvider>
//     <SidebarProvider>
//       {/* <WagmiConfig client={client}> */}
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       {/* </WagmiConfig> */}
//     </SidebarProvider>
//   </HelmetProvider>,
// );

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en', // Set the default language here
  resources: {
    en: { translation: enTranslation },
    bm: { translation: bmTranslation }
    // Add more languages here as needed
  }
});

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      {/* <WagmiConfig client={client}> */}
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
        ,
      </BrowserRouter>
      {/* </WagmiConfig> */}
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
