import { useRoutes, Routes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ThirdwebProvider, ChainId } from '@thirdweb-dev/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'events';

function App() {
  const content = useRoutes(router);
  const activechainid = ChainId.Polygon;
  const clientId = process.env.REACT_APP_THIRDWEB_CLIENT_ID;

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>  
        <CssBaseline />
        <ThirdwebProvider activeChain={activechainid} clientId={clientId}>
            {content}
        </ThirdwebProvider>
      </LocalizationProvider>
     </ThemeProvider>
  );
}
export default App;

