import {
  Button,
  CssBaseline,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';

const SentEmail = () => {
  const navigate = useNavigate();

  return (
    <>
      <CssBaseline />
      <div className="mainBg login">
        <form action="" style={{ width: '100%' }}>
          <Container maxWidth="sm" className="mainBox">
            <div className="mainBoxInnerWrapper layOutSize">
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto'
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&amp;token=c8928674-602c-4edf-9058-7941fa74fcdd"
                  className="Apple-logo1"
                />
                <h2>Reset Password</h2>
              </div>
              <div className="emailWrapper">
                <div className="inner">
                  <p
                    style={{
                      color: 'black',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    An email has been sent to your account to reset password!
                  </p>
                </div>
                <Button
                  onClick={() => navigate('/login')}
                  className="login-button"
                  sx={{
                    mt: 2
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px',
                    fontWeight: 400,
                    minHeight: '50px'
                  }}
                >
                  Back to Login
                </Button>
              </div>
            </div>
          </Container>
        </form>
      </div>
    </>
  );
};

export default SentEmail;
