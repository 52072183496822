import { Box, Container, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper
      className="footer-wrapper"
      style={{
        background: 'linear-gradient(90deg, #EAFEF1 0%, #27AA58 100%)',
        position: 'fixed',
        bottom: '0',
        right: '0',
        left: '0',
        width: '100%',
        maxWidth: 'unset',
        zIndex: 10
      }}
    >
      <Box
        // mt={2}
        // pb={2}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; 2023 - CLW Frontend
          </Typography>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
          style={{
            color: '#fff'
          }}
        >
          <Link
            href="https://cryptolegacyworlds.com/images/CLWT&CsPDF.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#fff'
            }}
          >
            Terms & Condition
          </Link>
          <span
            style={{
              color: '#fff',
              paddingLeft: 5,
              paddingRight: 5
            }}
          >
            |
          </span>
          <Link  
            href="https://cryptolegacyworlds.com/images/CLWPrivacyPolPDF.pdf"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#fff'
            }}
          >
            Privacy Policy
          </Link>
          <span
            style={{
              color: '#fff',
              paddingLeft: 5,
              paddingRight: 5
            }}
          >
            |
          </span>
          Crafted by{' '}
          <Link
            href="https://cryptolegacyworlds.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#fff'
            }}
          >
            CLW
          </Link>
        </Typography>
      </Box> 
    </FooterWrapper>
  );
}

export default Footer;
