import { useContext } from 'react';
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderMenu from './Menu';
import Languages from './Userbox/Languages';
import Wallet from './Userbox/Wallet';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

const Header = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const userid = localStorage.getItem('USER_ID');
  const Isloggedin = JSON.parse(localStorage.getItem('IsloggedIn'));
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [deactivateUserStatus, setDeactivateUserStatus] = useState(null);

  const deactivateStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOME_URL}/user/${userid}`
      );
      const data = response?.data?.data?.status;
      setDeactivateUserStatus(data);
    } catch (err) {
      console.log(err);
    }
    const value = JSON.stringify(deactivateUserStatus);
    if (value === 'false') {
      navigate('/login ');
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (Isloggedin) {
      deactivateStatus();
    }
  }, [location, deactivateUserStatus]);

  // console.log('deactivateUserStatus', deactivateUserStatus);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
        background:
          'linear-gradient(90deg, rgba(161, 219, 150, 0) 0%, #27AA58 100%)',
        height: '54px'
      }}
    >
        

      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        <Languages />
        <Wallet />

        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton  onClick={toggleSidebar} sx={{ color: '#fff', p:0}}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="medium" />
              ) : (
                <CloseTwoToneIcon fontSize="medium" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
