import { Button, Modal, Box, Avatar, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import NearMeIcon from '@mui/icons-material/NearMe';

const styleComment = {
  position: 'absolute' as 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 300,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 54,
  p: 4
};

const Comments = ({ comment, getAllPosts }) => {
  const USERID = localStorage.getItem('USER_ID');
  const [replyCommentModal, setReplyCommentOpen] = useState(false);
  const [isReplyToPostLoading, setisReplyToPostLoading] = useState(false);
  const [reply, setReply] = useState('');
  const [emptyReply, setEmptyReply] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const handleViewMore = () => {
    setShowReplies(!showReplies);
  };

  const handleReplyOpen = () => {
    setReplyCommentOpen(true);
  };

  const handleReplyClose = () => {
    setReply('');
    setEmptyReply(false);
    setReplyCommentOpen(false);
  };

  const handleReply = async (e) => {
    e.preventDefault();
    try {
      if (reply === '') {
        setEmptyReply(true);
        return;
      }
      setisReplyToPostLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_HOME_URL}/reply/add`,
        {
          commentId: comment?._id,
          reply: reply,
          userId: USERID
        }
      );
      setReply('');
      setisReplyToPostLoading(false);
      setEmptyReply(false);
      setReplyCommentOpen(false);
      getAllPosts();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 0,
          mb: 2
        }}
        className="commentsOuter"
      >
        <Box sx={{ p: 0 }} className="userBox">
          <Box className="userPics">
            <Avatar src={comment?.commentData[0]?.image} />
          </Box>
          <Box className="userName">{comment?.commentData[0]?.username}</Box>
        </Box>
        <Box className="comments">
          <span
            style={{
              marginLeft: '0px'
            }}
          >
            {' '}
            {comment?.comment}
          </span>
          <Button
            onClick={() => handleReplyOpen()}
            variant="text"
            color="primary"
            size="small"
            className="replytBtn fixed"
          >
            <NearMeIcon className="sendIcon" />
          </Button>
        </Box>
      </Box>

      {showReplies && (
        <>
          {comment?.replies?.map((reply, index) => (
            <Box
              key={index}
              sx={{
                p: 0,
                mb: 2
              }}
              className="replycommentsOuter"
            >
              <Box sx={{ p: 0 }} className="userBox">
                <Box className="userPics">
                  <Avatar src={reply?.repliesdata[0]?.image} />
                </Box>
                <Box className="userName">
                  {reply?.repliesdata[0]?.username}
                </Box>
              </Box>
              <Box className="replycomments">
                <span style={{ marginLeft: '0px' }}>{reply?.reply}</span>
                <Button
                  onClick={() => handleReplyOpen()}
                  variant="text"
                  color="primary"
                  size="small"
                  className="replytBtn fixed"
                >
                  <NearMeIcon className="sendIcon" />
                </Button>
              </Box>
            </Box>
          ))}
        </>
      )}

      <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {comment?.replies?.length > 0 && (
          <Button
            onClick={handleViewMore}
            color="primary"
            size="small"
            variant="outlined"
          >
            {showReplies ? 'Hide Replies' : 'View replies'}
          </Button>
        )}
      </Box>

      <Modal
        open={replyCommentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleReplyClose}
      >
        <Box sx={styleComment}>
          <textarea
            className="form-control"
            id="comment"
            placeholder="Reply to post..."
            name="comment"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
          {emptyReply && (
            <div className="invalid-data">This field is required.</div>
          )}

          <div style={{ marginLeft: '75%' }}>
            <Button
              sx={{ mt: 2, px: 2.5, py: 1.5 }}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleReply}
              disabled={isReplyToPostLoading}
            >
              {isReplyToPostLoading ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress size="1.5rem" color="inherit" />
                </Box>
              ) : (
                'Reply'
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Comments;
