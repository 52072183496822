import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  Avatar,
  TextField,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Container,
  Checkbox,
  CssBaseline,
  Divider,
  Link,
  Box,
  CircularProgress
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './Register.css';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../Footer';
import Header from '../Header/Header';
import { useTranslation } from 'react-i18next';

type UserSubmitForm = {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  referral_code: string;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSignUpLoading, setisSignUpLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState('');
  const day = useQuery();
  const referal = day?.get('referral');
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('This is a required field.')
      .matches(/^[a-zA-Z0-9.\-_$@*!]{3,30}$/, 'Username must start with a letter. No space is allowed'),
      // .min(4, 'Please enter a valid username')
      // .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('This is a required field.')
      .matches(
        /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        // /^[a-zA-Z0-9._%+-]+@(gmail\.com|yopmail\.com|yahoo\.com|outlook\.com|ilastword\.com)$/,
        'Please enter a valid email id.'
      ),
    password: Yup.string()
      .required('This is a required field.')
      .matches(
        /^(?=.*[^a-zA-Z])(?!.*\s).{8,}$/,
        'The password must contain 8 characters including 1 special and 1 lowercase. No space is allowed.'
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Password and Confirm Password must match.'
    )
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: UserSubmitForm) => {
    setisSignUpLoading(true);
    if (!isChecked) {
      setisSignUpLoading(false);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_HOME_URL}/user/register`, data)
      .then((mydata) => {
        setisSignUpLoading(false);
        navigate('/signupconfirm');
      })
      .catch((err) => {
        setisSignUpLoading(false);
        setInvalidUser(err?.response?.data?.message);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>CLW-Signup</title>
      </Helmet>
      <CssBaseline />
      <div className="mainBg RegisWrapp login">
        <Header />

        <form action="" className="formm" onSubmit={handleSubmit(onSubmit)}>
          <Container maxWidth="sm" className="mainBox">
            <div className="mainBoxInnerWrapper layOutSize">
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto'
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&amp;token=c8928674-602c-4edf-9058-7941fa74fcdd"
                  className="Apple-logo3"
                />
                <h2>{t('signupButton')}</h2>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <TextField
                    sx={{}}
                    id="name"
                    type="text"
                    placeholder={t('username')}
                    variant="outlined"
                    {...register('username')}
                  />
                  <div className="invalid-data">{errors.username?.message}</div>
                </div>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <TextField
                    sx={{}}
                    id="email"
                    type="email"
                    placeholder={t('EmailAdd')}
                    variant="outlined"
                    {...register('email')}
                  />
                  <div className="invalid-data">{errors.email?.message}</div>
                </div>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <FormControl sx={{}} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            className="eye-icon"
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="primary"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      placeholder={t('Pass')}
                      {...register('password')}
                    />
                  </FormControl>
                  <div className="invalid-data">{errors.password?.message}</div>
                </div>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <FormControl sx={{}} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showConfPass ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            className="eye-icon"
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfPass((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="primary"
                          >
                            {showConfPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                      placeholder={t('confPass')}
                      {...register('confirmPassword')}
                    />
                  </FormControl>
                  <div className="invalid-data">
                    {errors.confirmPassword?.message}
                  </div>
                </div>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <TextField
                    sx={{}}
                    id="code"
                    type="text"
                    placeholder={t('ReferralC')}
                    value={referal}
                    variant="outlined"
                    {...register('referral_code')}
                  />
                </div>
              </div>
              <small
                style={{ color: 'red', paddingBottom: '5px', display: 'block' }}
              >
                {invalidUser}
              </small>

              <div className="inner">
                <h6>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  {t('agree')}
                  <Link
                    href="https://cryptolegacyworlds.com/images/CLWT&CsPDF.pdf"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <b>{t('terms')}</b>
                  </Link>
                </h6>
              </div>

              <div className="inner">
                <Button
                  disabled={!isChecked && isSignUpLoading}
                  type="submit"
                  sx={{ mt: 1, mb: 2 }}
                  variant="contained"
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#27AA58',
                    color: '#ffffff',
                    border: '1px solid  rgb(147, 251, 66)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  {isSignUpLoading ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size="1.5rem" color="inherit" />
                    </Box>
                  ) : (
                    t('signupButton')
                  )}
                </Button>
              </div>
              <br />
              <div className="inner">
                <Button
                  onClick={() => navigate('/login')}
                  sx={{ mt: 1, mb: 2 }}
                  variant="contained"
                  style={{
                    margin: 'auto',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    color: '#27AA58',
                    border: '1px solid  rgb(147, 251, 66)',
                    fontSize: '20px',
                    lineHeight: '24px',
                    textAlign: 'center',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    padding: '12px'
                  }}
                >
                  {t('loginButton')}
                </Button>
              </div>
            </div>
          </Container>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Register;
