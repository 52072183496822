import { Button, FormControl } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const Tags = ({ tagshow, setState }) => {
  const [array, setarray] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const USERID = localStorage.getItem('USER_ID');
  const [tagsdata, setTagsData] = useState([]);
  let dataTagList = [];

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  var unique = array.filter(onlyUnique);

  useEffect(() => {
    setState(unique);
  }, [array]);

  useEffect(() => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setarray(selectedValues);

    // selectedOptions.map((data) => {
    //   // setarray([...array, data.value]);
    //   setarray((prevArray) => [...prevArray, data.value]);
    // });
  }, [selectedOptions]);

  const getReferredUserForTagging = () => {
    axios
      .get(`${process.env.REACT_APP_HOME_URL}/user/getmyreferreduser/${USERID}`)
      .then((res) => {
        for (let val of res?.data?.data[0]?.Tier1) {
          dataTagList.push({ ...val, tier: 1 });
        }
        for (let val of res?.data?.data[0]?.Tier2) {
          dataTagList.push({ ...val, tier: 2 });
        }

        setTagsData(dataTagList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReferredUserForTagging();
  }, []);

  const options = tagsdata?.map((item) => ({
    value: item._id,
    label: item.username
  }));

  const handleChange = (selectedOptions) => {
    // console.log("selectedOptions", selectedOptions)
    setSelectedOptions(selectedOptions);
  };

  // console.log('array', array);
  // console.log("options",options)
  // console.log('unique', unique);
  // console.log('selectedOptions', selectedOptions);

  return (
    <>
      <form>
        <FormControl>
          {tagshow && (
            <>
              <Select
                options={options}
                name={'tags'}
                value={selectedOptions}
                isMulti
                onChange={handleChange}
              />
            </>
          )}
        </FormControl>
      </form>
    </>
  );
};

// function handleSelect(e:any) {
//   // setSelectedOptions(data);

//   if (e.target.value) {
//     let filteredUsers = tagsdata.filter((user) => {
//       if (
//         user.username.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
//       ) {
//         return true;
//       }
//       return false;
//     });
//     setFilteredTags(filteredUsers);
//   } else {
//     setFilteredTags([]);
//   }
// }
// const handleValue = (e) => {
//   let { name, value, checked, type } = e.target;
//   value = type === 'checkbox' ? checked : value;
//   setCheckboxvalue(value);
//   // setValue(value);
// }
// console.log(checkboxvalue);

// {/*
//             {filteredtags.map((tags) => (
//               <div key={tags._id}>
//                 <span>
//                   <Checkbox value={checkboxvalue} onChange={handleValue}/>
//                 </span>
//                 <span>{tags.username}</span>
//               </div>
//             ))} */}

export default Tags;
