import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';
import { Container } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {};

const ResetPassword = (props: Props) => {
  const token = useParams();
  // console.log(token);
  const navigate = useNavigate();
  const [newpassword, setNewPassword] = React.useState('');
  const [confnewpassword, setConfNewPassword] = React.useState('');
  const [isResetLoading, setisResetLoading] = React.useState(false);
  const [newpassErr, setNewPassErr] = React.useState(false);
  const [invalidNewPass, setInvalidNewPass] = React.useState(false);
  const [invalidConfNewPass, setInvalidConfNewPass] = React.useState(false);
  const [PassValidate, setPassValidate] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfPassword, setShowConfPassword] = React.useState(false);
  const [tokenExpire, settokenExpire] = useState('');

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (newpassword) {
      setInvalidNewPass(false);
    }
    if (confnewpassword) {
      setInvalidConfNewPass(false);
    }
    if (newpassword === confnewpassword) {
      setNewPassErr(false);
    }
    if (
      /^(?=.*[^a-zA-Z])(?!.*\s).{8,}$/.test(newpassword) &&
      /^(?=.*[^a-zA-Z])(?!.*\s).{8,}$/.test(confnewpassword)
    ) {
      setPassValidate(false);
    }
  }, [newpassword, confnewpassword]);

  const tokenn = token?.token;
  const linkExpire = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOME_URL}/data/${tokenn}`);
      console.log("response",response)
    } catch (error) {
      if(error?.response?.data?.message){
        navigate('/tokenexpired')
      }
      // settokenExpire(error?.response?.data?.message);
      console.log("error",error);
    }
  }

  useEffect(() => {
    linkExpire();
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newpassword === '') {
      setInvalidNewPass(true);
      return;
    } else if (confnewpassword === '') {
      setInvalidConfNewPass(true);
      return;
    }
    if (newpassword !== confnewpassword) {
      setNewPassErr(true);
      return;
    }
    if (
      /^(?=.*[^a-zA-Z])(?!.*\s).{8,}$/.test(newpassword) &&
      /^(?=.*[^a-zA-Z])(?!.*\s).{8,}$/.test(confnewpassword)
    ) {
      setisResetLoading(true);
      let data = {
        newpassword: newpassword,
        resetpasswordtoken: token?.token
      };
      axios
        .post(`${process.env.REACT_APP_HOME_URL}/user/resetpassword`, data)
        .then((mydata) => {
          setisResetLoading(false);
          navigate('/login');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPassValidate(true);
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="mainBg login resetpass">
        <form action="" onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Container maxWidth="sm" className="mainBox">
            <div className="mainBoxInnerWrapper layOutSize">
              <div
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  margin: 'auto'
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vidhema-72384.appspot.com/o/apple%20(1)%202-svg.png?alt=media&amp;token=c8928674-602c-4edf-9058-7941fa74fcdd"
                  className="Apple-logo1"
                />
                <h2>Reset Password</h2>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="New Password"
                      name="newpassword"
                      value={newpassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </FormControl>
                  <LockIcon className="lockIcon" />
                  {invalidNewPass && (
                    <div className="invalid-data">
                      This is a required field.
                    </div>
                  )}
                  {PassValidate && (
                    <div className="invalid-data">
                      The password must contain 8 characters including 1
                      special, 1 uppercase, and 1 lowercase. No space is
                      allowed.
                    </div>
                  )}
                </div>
              </div>

              <div className="emailWrapper">
                <div className="inner">
                  <FormControl sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showConfPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Confirm Password"
                      name="confirmnewpassword"
                      value={confnewpassword}
                      onChange={(e) => setConfNewPassword(e.target.value)}
                    />
                  </FormControl>
                  <LockIcon className="lockIcon" />
                  {invalidConfNewPass && (
                    <div className="invalid-data">
                      This is a required field.
                    </div>
                  )}
                  {newpassErr && (
                    <div className="invalid-data">
                      Password and Confirm password must be same.
                    </div>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                sx={{
                  mt: 2
                }}
                color="primary"
                variant="contained"
                style={{
                  color: '#ffffff',
                  border: '1px solid  rgb(147, 251, 66)',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  fontSize: '20px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  letterSpacing: '0.06em',
                  textTransform: 'uppercase',
                  padding: '12px',
                  fontWeight: 400,
                  minHeight: '50px'
                }}
              >
                {isResetLoading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1.5rem" color="inherit" />
                  </Box>
                ) : (
                  'Reset Password'
                )}
              </Button>
            </div>
          </Container>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
